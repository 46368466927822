/**
 * @author  XuHongli
 * @date  2022/2/17 13:58
 * @version 1.0
 * @description Request 内网穿透测试
 */

import axios from 'axios'
import { Notification } from 'element-ui'
import { getToken, getusernameToken } from './cookie'
import router from '@/router/index'

const service = axios.create({
  // baseURL: 'http://vllshop.jingdandan.com/shop-line/',
  baseURL: '/test', // api 的 base_urlbv
  timeout: 1200000 // 请求超时时间 ms 2分钟
})
// request拦截器
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    if (getToken(getusernameToken())) {
      config.headers['Authorization'] = 'Bearer ' + getToken(getusernameToken()) // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    const code = response.status
    if (code < 200 || code > 300) {
      Notification.error({
        title: response.message
      })
      return Promise.reject('error')
    } else {
      // debugger
      if (response.data.code === 401) {
        router.push({ path: '/register', query: { registerType: 'login' }})
      }
      return response.data
    }
  }
)

export default service
