<template>
  <div>
    <heard />
    <div class="result">
      <a-result
        status="success"
        title="支付成功!"
        sub-title="`五秒后自动返回,请等待……"
      >
        <template #extra>
          <a-button key="console" type="primary" @click="backHome()">
            返回
          </a-button>
        </template>
      </a-result>
    </div>
  </div>
</template>

<script>
import heard from '@/views/shop/components/heard'

export default {
  name: 'Result',
  components: {
    heard
  },
  data() {
    return {
      maxNum: 5,
      maxNumsetIval: ''
    }
  },
  mounted() {
    const that = this
    that.maxNumsetIval = setInterval(function() {
      --that.maxNum
      if (that.maxNum < 0) {
        clearInterval(that.maxNumsetIval)
        that.backHome()
      }
    }, 1000)
  },
  methods: {
    backHome() {
      clearInterval(this.maxNumsetIval)

      const type = this.$route.query.type
      if (type === '1') {
        this.$router.push({ path: '/selection/home' })
        return
      }
      this.$router.push({ name: 'shopManagement' })
    }
  }
}
</script>

<style scoped>
.result {
  margin-top: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
