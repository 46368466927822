/**
 * @author  XuHongli
 * @date  2022/2/17 13:58
 * @version 1.0
 * @description Api 获取外部推广接口
 */

import request from '@/utils/TestRequest'

// 获取短链接
export function getLink(data) {
  return request({
    url: `/distribution/getPromoteCode`,
    method: 'get',
    data
  })
}
